import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
// Fonts Awesome
import "../node_modules/font-awesome/css/font-awesome.min.css";
// Styles
import "./styles/style.scss";
// Loading
import Loader from "./components/Loader";
// Components

import * as serviceWorker from "./serviceWorker";

const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <Suspense minDuration={2000} maxDuration={3200} fallback={<Loader />}>
    <App />
  </Suspense>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
